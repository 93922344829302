import axios from 'axios';

import { useEffect, useRef, useState } from "react";

// Redux.
import { connect } from 'react-redux';
import {
    setRelatedTopicClips,
    setTrack,
} from '../redux/actions/dataActions'

// Components.
import EntityInfoDrawer from './EntityInfoDrawer';
import SyncTranscriptAudio from './SyncTranscriptAudio';
import Transcript from './Transcript';
import PlayPauseBar from './PlayPauseBar';

import useIsInViewport from "./useIsInViewport";
import useWindowDimensions from "./windowHelper";

import Highlighter from 'react-highlight-words';

import { RESPONSIVE_SMALL_HEIGHT } from '../constants/sizes';
import { PLAYBACK_RATE } from '../constants/local_storage_constants';

import { ACCENT_COLOR } from '../constants/colors';
import { Drawer } from '@mui/material';
import ClipActions from './ClipActions';
import CloseFooter from './CloseFooter';
import { Refresh } from '@material-ui/icons';
import { AirlineStops } from '@mui/icons-material';
import { eventClipView } from './eventHelper';



const ClipCard = ({
    clip,
    clipIndexInViewport,
    currentTime,
    finishedClipIds,
    index,
    nextClip,
    playClip,
    playFullEpisode,
    playing,
    shouldLoadTrack,
    showAudioPlayerDrawer,

    // Redux state.
    authenticated,
    track,

    // Redux actions.
    setRelatedTopicClips,
    setTrack,
}) => {
    const clipCardViewport = useRef();
    const currentViewport = useRef();
    const transcriptContainerRef = useRef();
    const clipTopRef = useRef();

    const audio = document.getElementById('audio');

    const isCurrentViewport = useIsInViewport(currentViewport);
    const [localClip, setLocalClip] = useState(null);
    const [missingClip, setMissingClip] = useState(false);
    const [scrollToCurrentMessage, setScrollToCurrentMessage] = useState(true);

    const contentRef = useRef(null)
    const clipSourceBottomRef = useRef(null);
    const clipViewSeconds = useRef(new Set())

    const { height, width } = useWindowDimensions();

    useEffect(() => {
        if (scrollToCurrentMessage && audio.paused) {
            if (!finishedClipIds.current.has(clip.id)) {
                setTimeout(() => {
                    audio.play().catch(function () { });
                }, 1000);
            }

        }
    }, [scrollToCurrentMessage])

    const [reloadTranscript, setReloadTranscript] = useState(false);

    useEffect(() => {
        if (!isCurrentViewport) {
            // Reload transcript so when user goes back, the transcript starts at the top.
            setReloadTranscript(true);
            setTimeout(() => {
                setReloadTranscript(false);
            }, 100);
        } else {
            clipIndexInViewport(index);
            playClip(clip);
            const pathName = `/clip/${clip.fullTrackId}/${clip.messageIndex}`;
            window.history.replaceState(null, '', pathName);
        }
    }, [isCurrentViewport]);

    const scrollToClipCard = () => {
        const clipCardElem = document.getElementById(clip.id);
        if (clipCardElem) {
            clipCardElem.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "start" });
        }
    }

    useEffect(() => {
        const pathName = `/clip/${clip.fullTrackId}/${clip.messageIndex}`;
        if (shouldLoadTrack && !localClip) {
            if (track && track.pathname === pathName) {
                setLocalClip(track)
            } else {
                axios
                    .get(pathName)
                    .then((res) => {
                        setLocalClip(res.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        setMissingClip(true);
                    });
            }
        }
    }, [shouldLoadTrack]);

    useEffect(() => {
        if (!playing && isCurrentViewport && audio && audio.currentTime > 1) {
            setScrollToCurrentMessage(false);
        }
    }, [playing])
    
    useEffect(() => {
        if (playing && localClip && audio.src === localClip.mp3URL && isCurrentViewport) {
            const floorTimeSecs = Math.floor(currentTime);
            if (floorTimeSecs % 10 === 0) {
                if (!clipViewSeconds.current.has(floorTimeSecs)) {
                    clipViewSeconds.current.add(floorTimeSecs)
                    eventClipView(localClip.id, floorTimeSecs);
                }
            }
        }
    }, [currentTime, localClip, playing])

    useEffect(() => {
        if (isCurrentViewport && localClip && !showAudioPlayerDrawer) {
            setRelatedTopicClips(localClip.relatedTopicClips);
            setTrack(localClip);
            setScrollToCurrentMessage(true);
        }
    }, [isCurrentViewport, localClip, showAudioPlayerDrawer])

    const checkAudioSource = () => {
        if (audio.src !== clip.mp3URL) {
            playClip(clip);
        }
        if (localClip) {
            setTrack(localClip);
        }
    }

    const [openPersonInfo, setOpenPersonInfo] = useState(false);
    const selectedSpeakerId = useRef('');

    const openSpeakerInfo = (speakerId) => () => {
        selectedSpeakerId.current = speakerId;
        setTimeout(() => {
            setOpenPersonInfo(true);
        }, 100);
    }

    const togglePersonInfo = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenPersonInfo(open);
    }

    useEffect(() => {
        if (isCurrentViewport && !showAudioPlayerDrawer) {
            if (openPersonInfo) {
                audio.pause();
            } else {
                checkAudioSource();
                if (!finishedClipIds.current.has(clip.id)) {
                    audio.play().catch(function () { });
                }
            }
        }
    }, [openPersonInfo, isCurrentViewport, showAudioPlayerDrawer])

    const safeAreaBottom = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sab"));

    const clipTitleClassName = height > RESPONSIVE_SMALL_HEIGHT ? 'clipTitleLarge' : 'clipTitle'

    const clipTop = (
        <div ref={clipTopRef} className='clipTopInfo' style={{ zIndex: '99' }}>
            <div className={clipTitleClassName}>
                <Highlighter
                    highlightClassName="highlight"
                    searchWords={[]}
                    autoEscape={true}
                    textToHighlight={clip.title}
                />
            </div>
        </div>
    );

    const [showClipActions, setShowClipActions] = useState(false);
    const toggleClipActions = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (open) {
            audio.pause();
        } else {
            audio.play().catch(function () {
                // Browser doesn't allow play until user click.
            });
        }
        setShowClipActions(open);
    };
    const onSpeedClick = (val) => () => {
        audio.playbackRate = val;
        localStorage.setItem(PLAYBACK_RATE, val);
        toggleClipActions(false)();
    }

    const continueListeningFinishedClip = () => {
        playFullEpisode(localClip.clipStartTime + localClip.totalTimeSeconds);
        finishedClipIds.current.delete(clip.id);
    }

    const replayFinishedClip = () => {
        audio.play();
        finishedClipIds.current.delete(clip.id);
    }

    return (
        <div id={clip.id} ref={clipCardViewport} className='clip-slider hideScrollbar'
            style={{
                opacity: isCurrentViewport ? 1.0 : 0.6, width: `${Math.min(width, 540) - 40}px`,
                height: `${height - 84 - safeAreaBottom}px`
            }}>
            <EntityInfoDrawer
                open={openPersonInfo}
                onClose={togglePersonInfo(false)}
                speakerId={selectedSpeakerId.current} />

            <Drawer
                PaperProps={{
                    sx: {
                        borderRadius: '10px 10px 0px 0px'
                    }
                }}
                anchor='bottom' open={showClipActions} onClose={toggleClipActions(false)}>
                <div className='horizontalCenter hasCloseFooter'>
                    <div style={{ padding: '10px 0px 20px' }}>
                        <ClipActions
                            clip={clip}
                            onSpeedClick={onSpeedClick}
                        />
                        <CloseFooter onClick={toggleClipActions(false)} />
                    </div>
                </div>
            </Drawer>

            <div style={{ position: 'sticky', top: 0, marginTop: '-1px', maxHeight: '1px', textAlign: 'center' }}>
                <span style={{ display: 'inline-block', minWidth: '1px' }} ref={currentViewport}></span>
            </div>
            {missingClip ?
                <div className='heading center' style={{ paddingBottom: '20px', minWidth: '280px', textAlign: 'center' }} >
                    <div>
                        No longer available
                    </div>
                    <div style={{ padding: '20px' }}>
                        <button onClick={nextClip} style={{ paddingLeft: '14px', fontWeight: '600', fontSize: '20px', color: ACCENT_COLOR }}>
                            <span style={{ verticalAlign: 'middle' }}>
                                Next Clip
                            </span>
                        </button>
                    </div>
                </div>
                :
                <div ref={contentRef}>
                    {clipTop}
                    {!localClip || reloadTranscript || showAudioPlayerDrawer ?
                        <div className={isCurrentViewport ? 'glow' : ''}>
                            <div style={{ padding: '16px', overflowY: 'auto' }}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='loading' style={{ marginRight: '10px', height: '44px', width: '44px', borderRadius: '50%' }} />
                                            </td>
                                            <td style={{ width: '100%' }}>
                                                <div className='loading' style={{ borderRadius: '6px', height: '44px', width: '160px' }} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className='loading' style={{ margin: '8px 0px 0px', borderRadius: '8px', height: '70px', width: '100%' }} />
                                <div className='loading' style={{ margin: '8px 0px 0px', borderRadius: '8px', height: '70px', width: '100%' }} />
                                <div className='loading' style={{ margin: '8px 0px 0px', borderRadius: '8px', height: '70px', width: '100%' }} />
                                <div className='loading' style={{ margin: '8px 0px 0px', borderRadius: '8px', height: '70px', width: '100%' }} />
                            </div>
                        </div>
                        :
                        <div>
                            {finishedClipIds.current.has(localClip.id) ?
                                <div style={{ paddingTop: `${clipCardViewport.current.clientHeight / 2 - 120}px` }}>
                                    <div style={{ padding: '20px', textAlign: 'center' }} >
                                        <button onClick={continueListeningFinishedClip}
                                            style={{ padding: '13px 30px', textAlign: 'center', background: 'black', color: 'white', borderRadius: '40px' }}>
                                            <AirlineStops style={{ verticalAlign: 'middle', fontSize: '22px' }} />
                                            <span style={{ verticalAlign: 'middle', fontSize: '16px', fontWeight: '500', marginLeft: '6px' }} >Continue Listening</span>
                                        </button>
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        <button onClick={replayFinishedClip}>
                                            <Refresh style={{ verticalAlign: 'middle', fontSize: '18px' }} />
                                            <span style={{ verticalAlign: 'middle', fontSize: '15px', marginLeft: '6px' }} >Replay</span>
                                        </button>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div style={{ overflowY: 'auto' }}>
                                        <div ref={transcriptContainerRef}>
                                            <Transcript
                                                pathname={localClip.pathname}
                                                autoscrollPaddingTop={clipTopRef.current ?
                                                    clipTopRef.current.clientHeight + (height < RESPONSIVE_SMALL_HEIGHT ? 41 : 82) : 150}
                                                curTime={isCurrentViewport ? currentTime : 0}
                                                currentSection={0}
                                                isClip={true}
                                                isCurrentViewport={isCurrentViewport}
                                                openSpeakerInfo={openSpeakerInfo}
                                                playing={playing}
                                                scrollToCurrentMessage={scrollToCurrentMessage}
                                                setScrollToCurrentMessage={setScrollToCurrentMessage}
                                                setCurrentSpeaker={(currentSpeaker) => {
                                                    // unused current speacker
                                                }}
                                                scrollToTopTopPadding={clipTopRef.current.clientHeight + 10}
                                                transcriptContainerRef={clipCardViewport}
                                                topContent={<></>}
                                                track={localClip}
                                            />
                                        </div>
                                    </div>


                                    <SyncTranscriptAudio
                                        bottom={clipSourceBottomRef.current ? `${clipSourceBottomRef.current.clientHeight + 8}px` : '0px'}
                                        currentTime={currentTime}
                                        scrollToCurrentMessage={scrollToCurrentMessage}
                                        setScrollToCurrentMessage={setScrollToCurrentMessage}
                                        track={localClip}
                                    />

                                    <div ref={clipSourceBottomRef} className='clipSourceBottom'>
                                        {!isCurrentViewport ? <></>
                                            :
                                            <PlayPauseBar onMoreClick={toggleClipActions(true)} />
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
            {isCurrentViewport ? <></> :
                <button onClick={scrollToClipCard}>
                    <div className='notInViewportOverlay'>
                    </div>
                </button>
            }
        </div >
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    track: state.data.track,
})

const mapActionsToProps = {
    setRelatedTopicClips,
    setTrack,
}

ClipCard.propTypes = {

}

export default connect(mapStateToProps, mapActionsToProps)(ClipCard);