import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Redux.
import { connect } from "react-redux";
import {
    setNextClipsInfo,
} from '../redux/actions/dataActions';

// Components
import { loadTrack } from './audioHelper';
import ClipLargePreviewLoading from './ClipLargePreviewLoading';
import ClipLargePreview from './ClipLargePreview';
import { RESPONSIVE_LARGE_WIDTH } from '../constants/sizes';
import useWindowDimensions from './windowHelper';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { shuffle } from 'lodash';


const FirstUserClips = ({
    setNextClipsInfo,
}) => {
    const [loading, setLoading] = useState(false);
    const [clips, setClips] = useState([]);
    const [previewClipIndex, setPreviewClipIndex] = useState(0);

    const navigate = useNavigate();
    const { height, width } = useWindowDimensions();

    useEffect(() => {
        setLoading(true);
        axios
            .get('/first_user_clips')
            .then((res) => {
                setLoading(false);
                let shuffledClips = shuffle(res.data.clips);
                setClips(shuffledClips);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    }, []);

    const scrollToClipId = (clipId) => () => {
        const elem = document.getElementById(clipId);
        if (elem) {
            elem.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "start" });
        }
    }

    const prevClip = () => {
        const newIndex = Math.max(0, previewClipIndex - 1);
        const elem = document.getElementById(clips[newIndex].id);
        if (elem) {
            elem.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "start" });
        }
    }

    const nextClip = () => {
        const newIndex = Math.min(clips.length - 1, previewClipIndex + 1);
        const elem = document.getElementById(clips[newIndex].id);
        if (elem) {
            elem.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "start" });
        }
    }

    const playClip = (clipId) => () => {
        let clipIndex = 0;
        for (let i = 0; i < clips.length; i++) {
            if (clipId === clips[i].id) {
                clipIndex = i;
                break;
            }
        }
        const orderedClips = clips.slice(clipIndex, clips.length).concat(clips.slice(0, clipIndex))
        loadTrack(orderedClips[0].mp3URL, 0)
        setNextClipsInfo(orderedClips);
        navigate('/clips', { state: { closeAllModal: true } });
    }

    const previewClipsScrollContainerId = 'previewClipsScrollContainer';

    return (
        <div>
            {loading ?
                <div style={{ margin: width >= RESPONSIVE_LARGE_WIDTH ? '0px 40px' : '0px 0px' }}>
                    <ClipLargePreviewLoading count={1} />
                </div>
                :
                <div>
                    {!clips ?
                        <div style={{ padding: '20px' }}>Unexpected error, please try again later.</div>
                        :
                        <div style={{ position: 'relative' }}>
                            <div id={previewClipsScrollContainerId} style={{ margin: width >= RESPONSIVE_LARGE_WIDTH ? '0px 54px' : '0px 0px', scrollPadding: '20px' }} className='hslider-container hideScrollbar horizontalScroll'>
                                {clips.map((clip, clipIndex) => (
                                    <span key={clipIndex} className='hslider-children' style={{ paddingRight: clipIndex === clips.length - 1 ? '20px' : '10px' }}>
                                        <button onClick={clipIndex === previewClipIndex ? playClip(clip.id) : scrollToClipId(clip.id)} style={{ textAlign: 'left' }}>
                                            <ClipLargePreview clip={clip} index={clipIndex} clipIndexInViewport={(x) => { setPreviewClipIndex(x) }} />
                                        </button>
                                    </span>
                                ))}
                            </div>
                            {width >= RESPONSIVE_LARGE_WIDTH ?
                                <div style={{ float: 'left', position: 'absolute', top: '50%', left: '0px', transform: 'translateY(-50%)' }}>
                                    <button onClick={prevClip} className={'nextPrevButtons'} style={{ opacity: previewClipIndex === 0 ? 0.3 : 1 }}>
                                        <NavigateBefore style={{ color: 'rgb(30, 30, 30)', fontSize: '36px', verticalAlign: 'middle' }} />
                                    </button>
                                </div>
                                :
                                <></>}
                            {width >= RESPONSIVE_LARGE_WIDTH ?
                                <div style={{ float: 'right', position: 'absolute', top: '50%', right: '0px', transform: 'translateY(-50%)' }}>
                                    <button onClick={nextClip} className={'nextPrevButtons'} style={{ opacity: previewClipIndex + 1 === clips.length ? 0.3 : 1 }}>
                                        <NavigateNext style={{ color: 'rgb(30, 30, 30)', fontSize: '36px', verticalAlign: 'middle' }} />
                                    </button>
                                </div>
                                :
                                <></>}
                        </div>
                    }
                </div>
            }
        </div>
    );
};

const mapStateToProps = (state) => ({

});

const mapActionsToProps = {
    setNextClipsInfo,
};

export default connect(mapStateToProps, mapActionsToProps)(FirstUserClips);