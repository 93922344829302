import PropTypes from 'prop-types';
import axios from 'axios';

import { useEffect, useRef, useState } from "react";

// Redux.
import { connect } from 'react-redux';

// Firebase.
import { getAnalytics, logEvent } from "firebase/analytics";

// Components.
import useIsInViewport from "./useIsInViewport";
import useWindowDimensions from "./windowHelper";
import ProfilePicture from './ProfilePicture';
import { getColorForNum } from '../constants/colors';
import { fancyTimeFormatWithHMS, getDateString } from './timeHelper';
import { PREVIEW_CARD_HEIGHT, PREVIEW_CARD_MAX_WIDTH, PREVIEW_CARD_PROFILE_PIC_SIZE, PREVIEW_CARD_PROFILE_PIC_SIZE_NUM, PREVIEW_NEXT_CLIP_BUFFER } from '../constants/sizes';
import { PlayArrowRounded } from '@material-ui/icons';


const ClipLargePreview = ({
    clip,
    index,
    clipIndexInViewport,

    // Redux state.

    // Redux actions.
}) => {

    const currentViewport = useRef();

    const analytics = getAnalytics();
    const isCurrentViewport = useIsInViewport(currentViewport);

    const { _, width } = useWindowDimensions();
    const bottomDetailsRef = useRef();

    useEffect(() => {
        if (!isCurrentViewport) {

        } else {
            clipIndexInViewport(index);
        }
    }, [isCurrentViewport]);

    const padding = 20;

    return (
        <div id={clip.id}
            style={{
                // display: 'inline-block',
                opacity: 1.0,
                width: `${Math.min(width, PREVIEW_CARD_MAX_WIDTH) - PREVIEW_NEXT_CLIP_BUFFER}px`,
                background: `${getColorForNum(index)}35`,
                padding: `${padding}px`,
                borderRadius: '8px',
                height: `${PREVIEW_CARD_HEIGHT}px`,
            }}>

            <div style={{ position: 'sticky', top: 0, marginTop: '-1px', maxHeight: '1px', textAlign: 'center' }}>
                <span style={{ display: 'inline-block', minWidth: '1px' }} ref={currentViewport}></span>
            </div>
            <div>
                <div style={{ paddingTop: `${bottomDetailsRef.current ? (PREVIEW_CARD_HEIGHT - (2 * padding) - bottomDetailsRef.current.clientHeight - PREVIEW_CARD_PROFILE_PIC_SIZE_NUM - 20) / 2 : 0}px` }}>
                    <div className="profileAndSourceImageContainer">
                        <div className="profilePicWithSourceImage">
                            <ProfilePicture
                                borderSize={2}
                                imageSrc={clip.person.imageURL}
                                color={getColorForNum(index)}
                                profileClick={null}
                                size={PREVIEW_CARD_PROFILE_PIC_SIZE}
                            />
                        </div>
                    </div>
                </div>

                <div ref={bottomDetailsRef} style={{ position: 'absolute', bottom: '20px', width: `${Math.min(width, PREVIEW_CARD_MAX_WIDTH) - PREVIEW_NEXT_CLIP_BUFFER - (2 * padding)}px` }}>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <img style={{ borderRadius: '4px', verticalAlign: 'middle', width: '34px', marginRight: '8px' }} src={clip.source.imageURL} alt="source" />
                                    </div>
                                </td>
                                <td style={{ width: '100%' }}>
                                    <div className='clipLargePreviewSourceName'>
                                        {clip.source.name}
                                    </div>
                                    <div className='clipLargePreviewDetails'>
                                        <span>
                                            {fancyTimeFormatWithHMS(clip.totalTimeSeconds)}
                                        </span>
                                        <span style={{ padding: '0px 10px' }}>
                                            ·
                                        </span>
                                        <span>
                                            {getDateString(clip.publishedDate)}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='clipLargePreviewTitle' >
                        {clip.title}
                    </div>

                    <table>
                        <tbody>
                            <tr>
                                <td style={{ width: '100%' }}>
                                    <div className='clipLargePreviewSpeakerName'>
                                        {clip.person.name}
                                    </div>
                                    <div className='clipLargePreviewSpeakerDescription'>
                                        {clip.person.description}
                                    </div>
                                </td>
                                <td style={{ width: '47px', paddingLeft: '10px', textAlign: 'right', verticalAlign: 'middle' }}>
                                    <div style={{ width: '37px', height: '37px', borderRadius: '50%', background: 'rgb(255, 255, 255, 0.7)', textAlign: 'center', lineHeight: '37px' }}>
                                        <PlayArrowRounded style={{ verticalAlign: 'middle', fontSize: '30px', color: 'rgb(30, 30, 30)' }} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div >
    );
};

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {

}

ClipLargePreview.propTypes = {

}

export default connect(mapStateToProps, mapActionsToProps)(ClipLargePreview);